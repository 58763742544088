import moment from "moment";
import "./cards.css"

export default function ProductCardHorizontal({
  p,
  index,
  removeFromCart,
  isRemovable = true,
}) {
  return (
    <div
    style={{
      background: "none",
      border: "1px solid #000",
      borderRadius: "10px",
      padding: "7px",
      color: "#ccc",
      // maxWidth: "380px",
      marginLeft: "auto",
      marginRight: "auto"
    }}
      // style={{ maxWidth: "540px" }}
      key={index}
      className="card mb-3"
    >
      <div className="row g-0">
        <div className="col-4">
          <img
            className="img-fluid"
            src={`${process.env.REACT_APP_API}/products/photos/${p._id}`}
            alt={p.name}
            style={{
              // height: "150px",
              // width: "150px",
              height: "100px",
              width: "100px",
            }}
          />
        </div>
        <div className="col-8">
          {/**
           *  and should be its own compnent
           */}
          <div className="card-body p-0">
            <h5 className="card-title">
              {p.name}&nbsp;
              {p?.price?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </h5>
            <p className="card-text">
              {`${p?.description?.substring(0, 50)}`}...
            </p>
            <p className="card-text">
              <small className="">
                Listed {moment(p.createdAt).fromNow()}
              </small>
            </p>
          </div>
        </div>

        {isRemovable && (
          <div className="d-flex justify-content-sm-end">
            <p
              onClick={() => removeFromCart(p._id)}
              className="text-danger mb-2 rmv-btn"
              style={{
                fontSize: "small",
              }}
            >
              Remove
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
