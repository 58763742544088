import { useEffect, useState } from "react";
import AdminSideMenu from "../../components/nav/AdminSideMenu";
import { useAuth } from "../../context/auth";
import axios from "axios";
import { Select } from "antd";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function ManageProduct() {
  const { Option } = Select;
  const [auth, setAuth] = useAuth();

  const [categories, setCategories] = useState([]);
  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [shipping, setShipping] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [males, setMales] = useState(false);
  const [females, setFemales] = useState(false);
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");

  const navigate = useNavigate();

  const loadCategories = async () => {
    try {
      const { data } = await axios.get("/categories");
      //   console.log(data);
      setCategories(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadCategories();
  }, []);

  /**
   *
   * Form data must be sent to the server since we are including a file in the submission
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      /**
       * Create object to send via form to account for file of photo
       */
      const productData = new FormData();
      productData.append("photo", photo);
      productData.append("name", name);
      productData.append("description", description);
      productData.append("price", price);
      productData.append("category", category);
      productData.append("quantity", quantity);
      productData.append("shipping", shipping);
      productData.append("males", males);
      productData.append("females", females);
      productData.append("size", size);
      productData.append("color", color);

      // console.log(...productData);

      const { data } = await axios.post("/products", productData);
      if (data?.err) {
        toast.error(data.error);
      } else {
        toast.success(`"${name}" is created`);
        navigate("/dashboard/admin/products");
      }
    } catch (err) {
      console.log(err);
      toast.error("Product create failed. Check the fields and try again");
    }
  };

  return (
    <>
      <div className="container-fluid mt-5 p-5">
        <div className="row">
          <div className="col-md-3">
            <AdminSideMenu />
          </div>
          <div
            className="col-md-9 mx-auto"
            style={{ width: "50%", maxWidth: "500px" }}
          >
            <div className="p-3 mt-2 b-2 h4 admin-header" style={{ color: "#ccc" }}>
              Create a Product
            </div>

            <div className="">
              {photo && (
                <div className="d-flex justify-content-center">
                  <img
                    className="img-fluid p-3"
                    src={URL.createObjectURL(photo)}
                    alt="product photo"
                    height="20px"
                    style={{ maxWidth: "300px" }}
                  />
                </div>
              )}

              <div className="grid-container d-flex justify-content-between">
                <div>
                  <div className="p-3">
                    {/* <p>Select an image</p> */}
                    <label
                      className="btn btn-outline-secondary p-2 col-6  mb-3"
                      style={{ width: "100%", maxWidth: "250px" }}
                    >
                      <input
                        type="file"
                        name="photo"
                        accept="image/*"
                        onChange={(e) => setPhoto(e.target.files[0])}
                        hidden
                      />
                      {photo ? photo.name : "Click to upload photo"}
                    </label>
                  </div>

                  {/*
                   * Select Category
                   */}
                  <label>Category:</label>
                  <Select
                    bordered={false}
                    size="medium"
                    className="form-select mb-3 p-1"
                    placeholder="Choose a category"
                    onChange={(value) => setCategory(value)}
                    showSearch
                  >
                    {categories.map((c) => (
                      <Option key={c._id} value={c._id}>
                        {c.name}
                      </Option>
                    ))}
                  </Select>

                  <label>Name:</label>
                  <input
                    type="text"
                    className="form-control p-1 mb-3"
                    placeholder="Enter product name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <label>Description:</label>
                  <textarea
                    type="text"
                    className="form-control p-1 mb-3"
                    placeholder="Enter product description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />

                  <label>Price:</label>
                  <input
                    type="number"
                    className="form-control p-1 mb-3"
                    placeholder="Enter product price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />

                  <label>Quantity</label>
                  <input
                    min={1}
                    type="number"
                    className="form-control p-1 mb-3"
                    placeholder="Enter product quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </div>

                <div style={{marginTop: "90px"}}>
                  <label>Shipping:</label>
                  <Select
                    bordered={false}
                    size="medium"
                    className="form-select mb-3 p-1"
                    placeholder="Is the product shipping"
                    onChange={(value) => setShipping(value)}
                    //   showSearch
                  >
                    <Option value="0">No</Option>
                    <Option value="1">Yes</Option>
                  </Select>

                  <label>For females:</label>
                  <Select
                    bordered={false}
                    size="medium"
                    className="form-select mb-3 p-1"
                    placeholder="Is the product for males"
                    onChange={(value) => setFemales(value)}
                    //   showSearch
                  >
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                  </Select>

                  <label>For males:</label>
                  <Select
                    bordered={false}
                    size="medium"
                    className="form-select mb-3 p-1"
                    placeholder="Is the product for males"
                    onChange={(value) => setMales(value)}
                    //   showSearch
                  >
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                  </Select>

                  <label>Size:</label>
                  <Select
                    bordered={false}
                    size="medium"
                    className="form-select mb-3 p-1"
                    placeholder="Select product size"
                    onChange={(value) => setSize(value)}
                    //   showSearch
                  >
                    <Option value={"small"}>Small</Option>
                    <Option value={"medium"}>Medium</Option>
                    <Option value={"large"}>Large</Option>
                    <Option value={"x-large"}>X-Large</Option>
                  </Select>

                  <label>Color:</label>
                  <input
                    type="text"
                    className="form-control p-1 mb-3"
                    placeholder="Enter product color"
                    value={color}
                    onChange={(e) => setColor(e.target.value.toLowerCase())}
                  />
                </div>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              className="btn btn-primary col-6 mb-5 mx-auto d-flex"
            >
              Submit Product
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
