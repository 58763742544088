export const prices = [
    {
        _id: 0,
        name: 'Any',
        array: []

    },
    {
        _id: 1,
        name: '$0 to 19',
        array: [0, 20]

    },
    {
        _id: 2,
        name: '$20 to 39',
        array: [20, 39]

    },
    {
        _id: 3,
        name: '$40 to 59',
        array: [40, 59]

    },
    {
        _id: 4,
        name: '$60 to 79',
        array: [60, 79]

    },
    {
        _id: 5,
        name: 'More than $80',
        array: [80, 9999]

    },
]