import { useAuth } from "../../context/auth";
import Jumbotron from "../../components/cards/Jumbotron";
import { NavLink } from "react-router-dom";
import AdminSideMenu from "../../components/nav/AdminSideMenu";
import UserSideMenu from "../../components/nav/UserSideMenu";
import { useEffect } from "react";
import { checkCloseMobileMenu } from "../../util/Util";

export default function AdminDashboard() {
  /**
   * Access to global context/username
   */
  const [auth, setAuth] = useAuth();
  useEffect(() => {
    checkCloseMobileMenu();
  },[])

  return (
    <>
      <div className="container-fluid mt-5">
        <div className="row site-contain" style={{marginBottom: "100px"}}>
          <div className="col-md-3">
            <AdminSideMenu />
          </div>
          <div className="col-md-9">
            <div className="p-3 mt-2 b-2 h4 ml-spacer" style={{color: "#ccc"}}>Admin Information</div>
            <ul className="list-group dashboard-list-group ml-spacer">
              <label>
                Name:
                <li className="list-group-item">{auth?.user?.name}</li>
              </label>
              <label>
                Email:
                <li className="list-group-item">{auth?.user?.email}</li>
              </label>
              <label>
                Name:
                <li className="list-group-item">Admin</li>
              </label>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
