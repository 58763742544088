import axios from 'axios';
import React, { useState } from 'react'
import toast from 'react-hot-toast';

/**
* @author
* @function SendGridTest
**/

export const SendGridTest = (props) => {

    const [emailAddress, setEmailAddress] = useState('')
    const [message, setMessage] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        //Validate see if user already exist, then add user to our emailList table.
        try {
          const { data } = await axios.post("/send-test", {
            emailAddress,
            message
          });
      
          toast.success(`Email sent?`)
          console.log("Email Subscribe", data);
        } catch (err) {
        //   toast.error(err.response.data.error)
          // toast.error(<div>{err.response.data.error} <a href="#">TEST</a></div>)
          // console.log(err.response.data.error);
          console.log(err);
        }
      };

  return(
    <div>
        <form
                  onSubmit={handleSubmit}
                  className="mt-3 d-flex flex-column"
                >
                  <input
                    className="py-2 px-1"
                    type="email"
                    placeholder="Enter email"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value) }
                    style={{ color: "#000" }}
                  />
                  <br/>
                  <textarea
                    className="py-2 px-1"
                    type="text"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{ color: "#000" }}
                  />
                  <input
                    className="mt-3 py-2 btn btn-alpha w-100"
                    style={{ border: "1px solid" }}
                    type="submit"
                    value="Submit"
                    // onSubmit={(e) => handleEmailSubscribe}
                    // onClick={(e) => handleEmailSubscribe}
                  />
                </form>
    </div>
   )

 }