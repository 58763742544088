import React from "react";

/**
 * @author
 * @function SearchBar
 **/

export const SearchBar = (props) => {
  return (
    // <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
    <div style={{display: "none", justifyContent: "flex-end", alignItems: "center"}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        style={{ color: "#ccc", marginRight: "10px" }}
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </svg>
      <input style={{maxWidth: "300px", maxHeight: "27px", backgroundColor: "#000", color: "#ccc"}} type="text" />
    </div>
  );
};
