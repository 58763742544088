import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ViewEmailDetails.css";

/**
 * @author
 * @function ViewEmailDetails
 **/

export const ViewEmailDetails = (props) => {
  const params = useParams();
  const [emailData, setEmailData] = useState("");

  const markAsRead = async () => {
    // alert('test')
    const {data} = await axios.post('/mark-email-as-read', {id: params.id})
  }

  useEffect(() => {
    const getEmailDetails = async () => {
      const { data } = await axios.get(`/getEmailDetails/${params.id}`);
      // console.log(data)
      setEmailData(data[0]);
      console.log(emailData);
    };
    console.log(params);

    getEmailDetails();
  }, []);
  return (
    <div className="contain">
      <div className="border">
        <p className="font mb-0">{emailData.name}</p>
        <p className="font small">{emailData.email}</p>
        {emailData.subject ? (
          <p className="font">Subject: {emailData.subject}</p>
        ) : (
          <p className="font">Subject: No Subject</p>
        )}
        <p className="font body">{emailData.message}</p>
        <p className="font small">Sent on {new Date(emailData.createdAt).toLocaleDateString()}</p>
        {
            emailData.isRead && <p className="text-success small">Email marked as read</p>
        }
        {
            !emailData.isRead && <a onClick={markAsRead} className="text-warning small">Click here to mark this message as read</a>
        }
        
      </div>
    </div>
  );
};
