import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth";
import Jumbotron from "../../components/cards/Jumbotron";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { checkCloseMobileMenu } from "../../util/Util";

export default function Register() {
  const [name, setName] = useState("Tester");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    checkCloseMobileMenu();
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`/register`, {
        name,
        firstname,
        lastname,
        email,
        password,
      });

      // setAuth({ ...auth, user: data.user });

      console.log(data);


      /**
       * This error happens if there is a problem returned from the server, but the request did reach the endpoint
       * Correction, having the '?' tells us the data might be undefined
       */
      if (data?.error) {
        /**
         * LOGIN FAIL
         */
        console.log(data.error);
        toast.error(data.error);
      } else {
        /**
         * SUCCESSFUL LOGIN
         * Save to local storage for more extra surety
         */
        // localStorage.setItem("auth", JSON.stringify(data));
        // setAuth({ ...auth, token: data.token, user: data.user });
        toast.success("Registration successful");
        // navigate("/dashboard/user");
        navigate("/login");
      }
    } catch (err) {
      /**
       * This error only happens when there is a problem with sending all together
       */
      console.log(err);
      toast.error("Registration error");
    }
  };


  return (
    <div className="page-component">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <h2 className="mt-5 d-flex justify-content-center">Create an account</h2>
            <form onSubmit={handleSubmit}>
              {/* <label className="form-label">
                Name:
                <input
                  type="text"
                  className="form-control mb-4 p-2"
                  // placeholder="Enter Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </label> */}

              <label className="form-label">
                First Name:
                <input
                  type="text"
                  className="form-control mb-4 p-2"
                  // placeholder="Enter Your First Name"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </label>

              <label className="form-label">
                Last Name:
                <input
                  type="text"
                  className="form-control mb-4 p-2"
                  // placeholder="Enter Your Last Name"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
              </label>

              <label className="form-label">
                Email:
                <input
                  type="email"
                  className="form-control mb-4 p-2"
                  // placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>

              <label className="form-label">
                Password:
                <input
                  type="password"
                  className="form-control mb-4 p-2"
                  // placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>

              <button className="btn btn-alpha d-flex justify-content-center mx-auto" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
