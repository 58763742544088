export default function CategoryForm({
  value,
  setValue,
  handleSubmit,
  submitText,
  isDeleteBtn,
  handleDelete
}) {
  return (
    <div className="p-3">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className="form-control p-3"
          placeholder="Write category name"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <div className="d-flex justify-content-around">
          <button className="btn btn-primary mt-3">{submitText}</button>
          {isDeleteBtn && (
            <button className="btn btn-danger mt-3" onClick={handleDelete}>Delete</button>
          )}
        </div>
      </form>
    </div>
  );
}
