import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingGIF from "../../images/loading.gif";

export default function Loading({countDown, redirectPage, message}) {
  const [count, setCount] = useState(countDown);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((count) => --count);
    }, [1000]);

    //Redirect once count equals zero:
    /**
     * If <Login/> detects that there is a "state", (meaning user is signed in), it will take them to that location instead of <Login/>
     */
    if (count <= 0) navigate(redirectPage, {
      state: location.pathname,
    });

    //clean up?:
    return () => clearInterval(interval);
  }, [count]);

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{ height: "80vh" }}
    >
      <p className="text-white d-flex justify-content-center">You will be redirected in {count} seconds.</p>
      {/* <p className="text-white d-flex justify-content-center">{message}</p> */}
      <br/>
    <img src={LoadingGIF} alt="loading" />
    </div>
  );
}
