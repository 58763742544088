import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingGIF from "../../images/loading.gif";

export default function Unauthorized() {
  const [count, setCount] = useState(3);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((count) => --count);
    }, [1000]);

    //Redirect once count equals zero:
    /**
     * If <Login/> detects that there is a "state". it will take them to that location instead of <Login/>
     */
    if (count <= 0) navigate("/login", {
      state: location.pathname,
    });

    //clean up?:
    return () => clearInterval(interval);
  }, [count]);

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{ height: "80vh" }}
    >
      <p>401: Unauthorized. Re-routing in {count} seconds.</p>
      <br/>
    <img src={LoadingGIF} alt="loading" />
    </div>
  );
}
