import { useEffect, useState } from "react";
import axios from "axios";
import { useSearch } from "../../context/search";
import { useNavigate } from "react-router-dom";

export default function Search() {
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState([]);
  /**
   * Access to the search context/hook
   */
  const [values, setValues] = useSearch();
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(`/products/search/${values.keyword}`);
      console.log(data);

      navigate("/search")

      setValues({ ...values, results: data });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form className="d-flex" onSubmit={handleSubmit}>
      <input
        type="search"
        className="form-control"
        style={{ borderRadius: "0px" }}
        placeholder="Search"
        onChange={(e) => setValues({ ...values, keyword: e.target.value })}
        value={values.keyword}
      />
      <button
        type="submit"
        style={{ borderRadius: "0px" }}
        className="btn btn-outline-primary"
      >
        Search {values.results.length}
      </button>
    </form>
  );
}
