import { useEffect, useState } from "react";
import axios from "axios";
import Jumbotron from "../components/cards/Jumbotron";
import ProductCard from "../components/cards/ProductCard";
import { Checkbox, Radio } from "antd";
import { prices } from "../prices";
import { checkCloseMobileMenu } from "../util/Util";
import { useParams } from "react-router-dom";

export default function Shop() {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filterCategories, setFilterCategories] = useState([]);
  const [filterPrice, setFilterPrice] = useState([]);

  const [isFilterCategoriesUpdated, setIsFilterCategoriesUpdated] =
    useState(false);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

  //Filter Options isChecked controllers:
  /**
   * Hard-coded value of 4 options: 'Shirts, Pants, Hoodies, Accessories
   */
  const [categoriesFilterController, setCategoriesFilterController] = useState([
    false,
    false,
    false,
    false,
  ]);

  //Filter Options Price radio buttons controller:
  /**
   * Hard-coded 6 values for 'Any', '0-19', '20-39', '40-59', '60-79', 'More than 80'
   */
  const [pricesFilterController, setPricesFilterController] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const params = useParams();

  useEffect(() => {
    if (!filterCategories.length && !filterPrice.length) {
      loadProducts();
      loadCategories();
      checkCloseMobileMenu();

      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (isFilterCategoriesUpdated) {
      loadFilteredProducts();
      setIsFilterCategoriesUpdated(false); // Reset the flag
    }
  }, [isFilterCategoriesUpdated, filterPrice]);

  useEffect(() => {
    const param = params.param;
    if (param) {
      setFilter(param);
    } else {
      // alert('No parameters found');
    }
  }, [params]);

  const setFilter = async (filter) => {
    let url;
    let checkedIndex;

    if (filter === "hoodies") {
      // handleCheckBox
      url = "/category/hoodies";
      checkedIndex = 2;
    } else if (filter === "shirts") {
      url = "/category/shirts";
      checkedIndex = 0;
    } else if (filter === "pants") {
      url = "/category/pants";
      checkedIndex = 1;
    } else if (filter === "accessories") {
      url = "/category/accessories";
      checkedIndex = 3;
    }

    try {
      const { data } = await axios.get(url);
      handleCheckBox(true, data._id, checkedIndex);
    } catch (error) {
      console.log(error);
    }

    // console.log(data)

    // setCategoriesFilterController
  };

  // /** Whenever the price or category filter is changed, this effect happens */
  // useEffect(() => {
  //   if (filterCategories.length || filterPrice.length) loadFilteredProducts();
  // }, [filterCategories, filterPrice]);

  const loadProducts = async () => {
    try {
      let url;
      if (process.env.NODE_ENV === "development") {
        url = "/products";
      } else if (process.env.NODE_ENV === "production") {
        url = "/products";
      } else {
        throw new Error("Unknown NODE_ENV");
      }

      const { data } = await axios.get(url);
      if (data) setProducts(data);
    } catch (err) {
      console.log(err);
    }
  };

  const loadCategories = async () => {
    try {
      let url;
      if (process.env.NODE_ENV === "development") {
        url = "/categories";
      } else if (process.env.NODE_ENV === "production") {
        url = "/categories";
      } else {
        throw new Error("Unknown NODE_ENV");
      }

      const { data } = await axios.get(url);
      setCategories(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCheckBox = async (value, id, checkedIndex) => {
    console.log(value);
    setCategoriesFilterController((prevState) =>
      prevState.map((item, idx) => (idx === checkedIndex ? !item : item))
    );

    let all = [...filterCategories];

    if (value) {
      all.push(id);
    } else {
      all = all.filter((c) => c !== id);
    }

    // console.log(all);

    setFilterCategories(all);

    // console.log("FILTER: ", filterCategories);

    // //handle check/uncheck logic:

    // await loadFilteredProducts();

    setIsFilterCategoriesUpdated(true);
  };

  const handleRadioButton = async (value, radioIndex) => {
    setPricesFilterController((prevState) =>
      prevState.map((item, idx) => (idx === radioIndex ? !item : item))
    );

    setFilterPrice(value);

    setIsFilterCategoriesUpdated(true);
  };

  const loadFilteredProducts = async () => {
    try {
      const { data } = await axios.post("/filtered-products", {
        filterCategories,
        filterPrice,
      });

      console.log("filtered products = ", data);
      setProducts(data);
    } catch (err) {}
  };

  const toggleFilterMenu = () => {
    let mobileFilterMenu =
      document.getElementsByClassName("filter-mobile-menu")[0];
    let mobileFilterMenuBody = document.getElementsByClassName(
      "filter-mobile-menu-body"
    )[0];
    let overlay = document.getElementById("overlay-alpha");

    if (!mobileFilterMenuBody.classList.contains("mobile-menu-body-open")) {
      // mobileFilterMenu.classList.add("mobile-menu-btn-open");
      mobileFilterMenuBody.classList.add("mobile-menu-body-open");
      overlay.classList.add("overlay-active");
    } else {
      // mobileFilterMenu.classList.remove("mobile-menu-btn-open");
      mobileFilterMenuBody.classList.remove("mobile-menu-body-open");
      overlay.classList.remove("overlay-active");
    }

    setIsMobileFilterOpen(!isMobileFilterOpen);
  };

  const FilterOptions = () => {
    return (
      <>
        <h2 className="p-3 mt-2 mb-2 h4 mx-2">Filter by Categories</h2>
        <div style={{ marginLeft: "25px" }} className="row">
          {categories.map((c, index) => (
            <div key={c._id} style={{ marginLeft: "0px" }}>
              <Checkbox
                checked={categoriesFilterController[index]}
                // onChange={(e) =>{ handleCheckBox(e.target.value, c._id, index)}}
                onChange={(e) => {
                  handleCheckBox(e.target.checked, c._id, index);
                }}
              >
                {c.name}
              </Checkbox>
            </div>
          ))}
        </div>

        <h2 className="p-3 mt-2 mb-2 h4 mx-2">Filter by Price</h2>
        <div style={{ marginLeft: "25px" }} className="row px-5">
          {/* <Radio.Group onChange={(e) => setFilterPrice(e.target.value)}> */}
          <Radio.Group
            onChange={(e) => handleRadioButton(e.target.value, 0)}
            value={filterPrice}
          >
            {prices.map((p, index) => (
              <div key={p._id} style={{ marginLeft: "-40px" }}>
                <Radio value={p.array} checked={pricesFilterController[index]}>
                  {p.name}
                </Radio>
              </div>
            ))}
          </Radio.Group>
        </div>
        <div className="p-5 pt-0 row">
          <button
            style={{ marginLeft: "10px", color:"#000" }}
            className="btn btn-outline-secondary col-12 mt-5"
            onClick={() => (window.location.href = `/#/shop`)}
          >
            Reset Filters
          </button>
        </div>
      </>
    );
  };

  const filterOverlayOpen = {
    height: "100vh",
    width: "100vw",
    position: "absolute",
    zIndex: "5",
    // backgroundColor: 'red'
  };

  const none = {};

  return (
    <>
      <div
        style={isMobileFilterOpen ? filterOverlayOpen : none}
        id="filter-overlay"
        onClick={() => toggleFilterMenu()}
      ></div>
      <div className="container-fluid page-component">
        <div className="row site-contain">
          <div className="col-3 mt-4">
            <div className="d-none d-md-block">
              <FilterOptions />
            </div>
            <div className="d-block d-md-none">
              <div
                id="dashboard-mobile-menu"
                class="filter-mobile-menu mobile-side-menu filter-mobile-mobile-menu-btn mx-4"
                onClick={() => toggleFilterMenu()}
                style={{
                  transform: "rotate(0deg)",
                  color: "#000",
                  border: "none !important",
                  position: "fixed",
                }}
              >
                {isMobileFilterOpen ? "Hide " : "Show "} <br /> Filters
              </div>
              <div
                id="filter-options-mobile-menu-body"
                className="mobile-side-menu filter-mobile-menu-body mobile-menu-items"
                style={{
                  border: "none",
                  height: "250px",
                  padding: "0px",
                  position: "fixed",
                  left: "-420px",
                }}
              >
                <div
                  className="list-group mobile-list-group"
                  style={{
                    marginLeft: "-510px",
                    // backgroundColor: "#191919",
                    backgroundColor: "#ccc",
                    width: "fit-content",
                    border: "1px solid #000",
                    padding: "5px",
                    marginTop: "45px",
                    zIndex: "10",
                    position: "fixed",
                  }}
                >
                  <FilterOptions />
                </div>
              </div>
            </div>
          </div>

          <div className="col-9">
            {/* <h2 className="p-3 mt-2 mb-2 h4 text-center">Products</h2> */}
            <h2 className="p-3 mt-2 mb-2 h4 text-center">&nbsp;</h2>
            <div className="row" style={{}}>
              {products.map((p) => (
                // <div className="col-md-4">
                <div className="col-11 col-sm-6 col-md-4">
                  <ProductCard key={p.id} product={p} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
