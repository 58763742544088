import React from "react";
import moment from "moment";

/**
 * @author
 * @function AdminViewAllProductsCards
 **/

export const AdminViewAllProductsCards = ({ p }) => {
    
  return (
    <div className="card mb-3" style={{background: "none", border: "1px solid #ccc"}}>
      {/* <div className="card mb-3"> */}
        <div className="row g-0">
          <div className="col-md-4">
            <img
              className="img-fluid rounded-start"
              src={`${process.env.REACT_APP_API}/products/photos/${p._id}`}
              alt={p.name}
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">{p.name}</h5>
              <p className="card-text">{p.description}</p>
              <p className="card-text">
                <small className="muted" style={{color: "grey"}}>
                  {moment(p.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                </small>
              </p>
            </div>
          </div>
        </div>
      {/* </div> */}

    </div>
  );
};
