import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth";
import Jumbotron from "../../components/cards/Jumbotron";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { checkCloseMobileMenu } from "../../util/Util";

export default function Login() {
  const [auth, setAuth] = useAuth();
  const [name, setName] = useState("Tester");
  // const [email, setEmail] = useState("testClientSide@test.com");
  const [email, setEmail] = useState(
    `${process.env.NODE_ENV === "development" ? "testClientSide@test.com" : ""}`
  );
  const [password, setPassword] = useState(
    `${process.env.NODE_ENV === "development" ? "123456" : ""}`
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkCloseMobileMenu();
    console.log(process.env.NODE_ENV);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`/login`, {
        email,
        password,
      });

      console.log("LOGIN!")
      console.log(data);

      /**
       * LOGIN ERROR
       * This error happens if there is a problem returned from the server, but the request did reach the endpoint
       * Correction, having the '?' tells us the data might be undefined
       */
      if (data?.error) {
        console.log(data.error);
        toast.error(data.error);
      } else {
        /**
         * SUCCESSFUL LOGIN
         * We will save to local storage as well so the user's credentials are saved well
         * Things saved to localstorage must be in JSON format
         */
        localStorage.setItem("auth", JSON.stringify(data));
        setAuth({ ...auth, token: data.token, user: data.user });
        toast.success("Login successful");
        /**
         * If the user wants to go to a specific protected webpage, that will be in location.state:
         */
        navigate(
          location.state ||
            `/dashboard/${data?.user?.role === 1 ? "admin" : "user/profile"}`
        );
      }
    } catch (err) {
      /**
       * This error only happens when there is a problem with sending all together
       */
      console.log(err);
      toast.error("Login error");
    }
  };

  return (
    <div className="page-component">
      <div className="container">
        <div className="row">
          <h2 className="my-5 mx-auto d-flex justify-content-center">
            Login to your account
          </h2>
          <div className="col-md-6 offset-md-3">
            <form onSubmit={handleSubmit} style={{marginBottom: "100px"}}>
              <label className="form-label">
                Email:
                <input
                  type="email"
                  className="form-control mb-4 p-2"
                  // placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>

              <label className="form-label">
                Password:
              <input
                type="password"
                className="form-control mb-4 p-2"
                // placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              </label>

              <button
                className="btn btn-alpha d-flex justify-content-center mx-auto"
                type="submit"
              >
                Login
              </button>

              <Link
                className="btn btn-bravo d-flex justify-content-center mx-auto mt-3"
                to={"/register"}
              >
                Become a member
              </Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
