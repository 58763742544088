import { NavLink } from "react-router-dom";

export default function UserSideMenu() {
  const toggleMobileMenu = () => {
    let mobileMenu = document.getElementById("dashboard-mobile-menu");
    let mobileMenuBody = document.getElementById("dashboard-mobile-menu-body");
    let overlay = document.getElementById("overlay-alpha");


    if (!mobileMenu.classList.contains("mobile-menu-btn-open")) {
      mobileMenu.classList.add("mobile-menu-btn-open");
      mobileMenuBody.classList.add("mobile-menu-body-open");
      overlay.classList.add("overlay-active");
    } else {
      mobileMenu.classList.remove("mobile-menu-btn-open");
      mobileMenuBody.classList.remove("mobile-menu-body-open");
      overlay.classList.remove("overlay-active");
    }
  };

  const alertTest = () => {
    alert('testing');
  }

  return (
    <>
      {/** Large screen menu */}
      <div
        className="mt-3 desktop-side-menu"
        style={{
          border: "1px dotted #ccc",
          padding: "0px 10px 10px 10px",
          borderRadius: "10px",
        }}
      >
        <div
          className="p-2 mt-2 b-2 h4"
          style={{ color: "#ccc", borderBottom: "1px solid" }}
        >
          Dashboard Menu
        </div>
        <ul className="list-group">
          <li>
            <NavLink
              className="list-group-item list-link"
              to="/dashboard/user/profile"
            >
              Profile
            </NavLink>
          </li>
          <li>
            <NavLink
              className="list-group-item list-link"
              to="/dashboard/user/orders"
            >
              Orders
            </NavLink>
          </li>
        </ul>
      </div>

      {/** Mobile screen menu */}
      <div
        id="dashboard-mobile-menu"
        className="mobile-side-menu mobile-menu-btn"
        onClick={() => toggleMobileMenu()}
      >
         Menu
      </div>
      <div style={{border: "1px solid", height: "250px", padding: "5px 5px"}} id="dashboard-mobile-menu-body" className="mobile-side-menu mobile-menu-items">
        <div style={{}} className="list-group mobile-list-group">
          <p>Dashboard Menu</p>
          <ul style={{ listStyle: "none", marginLeft:"-20%", width:"180%" }}>
            <li>
              <NavLink
                className="list-group-item list-link"
                to="/dashboard/user/profile"
              >
                Profile
              </NavLink>
            </li>
            <li>
              <NavLink
                className="list-group-item list-link"
                to="/dashboard/user/orders"
              >
                Orders
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
