import { HashRouter as BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Menu from "./components/nav/Menu";
/**
 * Toaster is needed to generate a toast. Component must be used, but can be used anywhere in the return()
 */
import { Toaster } from "react-hot-toast";
import Dashboard from "./pages/user/Dashboard";
import PrivateRoute from "./components/routes/PrivateRoute";
import Secret from "./pages/auth/Secret";
import AdminDashboard from "./pages/admin/AdminDashboard";
import AdminRoute from "./components/routes/AdminRoute";
import ManageCategory from "./pages/admin/ManageCategory";
import ManageProduct from "./pages/admin/ManageProduct";
import UserProfile from "./pages/user/UserProfile";
import UserOrders from "./pages/user/UserOrders";
import AdminProducts from "./pages/admin/AdminProducts";
import UpdateProduct from "./pages/admin/UpdateProduct";
import Shop from "./pages/Shop";
import Search from "./pages/Search";
import ProductView from "./pages/ProductView";
import Cart from "./pages/Cart";
import AdminOrders from "./pages/admin/ManageOrders";
import HomeBravo from "./pages/HomeBravo";
import Overlays from "./components/misc/Overlays";
import Footer from "./components/sections/Footer";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import Verify from "./pages/auth/Verify";
import { ContactEmailVerify } from "./pages/ContactEmailVerify";
import { ViewEmails } from "./pages/admin/ViewEmails";
import { ViewEmailDetails } from "./pages/admin/ViewEmailDetails";
import { HomeCharlie } from "./pages/HomeCharlie";
import { SendGridTest } from "./pages/admin/SendGridTest";

const PageNotFound = () => {
  return (
    <h1 className="d-flex justify-content-center align-items-center vh-100">
      404: Page Not Found
    </h1>
  );
};

export default function App() {
  return (
    <BrowserRouter>
      <Menu />
      <Overlays />
      {/* 
        A toaster instance must be used somewhere in order to generate a toast
      */}
      <Toaster />
      <Routes>
        {/**
         * PUBLIC ROUTES
         */}
        {/* <Route path="/" element={<HomeBravo />} /> */}
        <Route path="/" element={<HomeCharlie />}/>
        <Route path="/bravo" element={<HomeBravo />}/>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/:param" element={<Shop />} />
        <Route path="/search" element={<Search />} />
        <Route path="/products/:slug" element={<ProductView />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/verify/:token" element={<Verify />} />

        {/*
         *PRIVATE ROUTES 
         <PrivateRoute /> denies any who is not logged in, <AdminDashboard /> denies any who is not admin
         */}
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route path="" element={<Dashboard />} />
          <Route path="/dashboard/user/profile" element={<UserProfile />} />
          <Route path="/dashboard/user/orders" element={<UserOrders />} />

          {/**ADMIN ROUTES */}
          <Route path="/dashboard/admin" element={<AdminRoute />}>
            <Route path="" element={<AdminDashboard />} />
            <Route
              path="/dashboard/admin/category"
              element={<ManageCategory />}
            />
            <Route
              path="/dashboard/admin/products"
              element={<AdminProducts />}
            />
            <Route
              path="/dashboard/admin/product/update/:slug"
              element={<UpdateProduct />}
            />
            <Route
              path="/dashboard/admin/product"
              element={<ManageProduct />}
            />
          </Route>
          <Route path="/dashboard/admin/orders" element={<AdminOrders />} />
          <Route
            path="/dashboard/admin/contact-email-verify/:token"
            element={<ContactEmailVerify />}
          />
          <Route 
            path="/dashboard/admin/view-emails"
            element={<ViewEmails />}
          />
          <Route 
            path="/dashboard/admin/view-emails/:id"
            element={<ViewEmailDetails />}
          />
          <Route 
            path="/dashboard/admin/send"
            element={<SendGridTest />}
          />
        </Route>

        <Route path="*" element={<PageNotFound />} replace />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
