import Jumbotron from "../components/cards/Jumbotron";
import ProductCard from "../components/cards/ProductCard";
import { useSearch } from "../context/search"

export default function Search(){

    const [values, setValues] = useSearch();
    
    return(
        <>
            <Jumbotron 
                title="Search results"
                subtitle={values?.results?.length < 1 ? "No products found" : `Found ${values.results.length} result(s)`}
            />

            <div className="container mt-3">
                <div className="row">
                {
                    values?.results?.map(p => (
                        <div key={p._id} className="col-md-4"> 
                            <ProductCard
                                product={p}
                            />
                        </div>
                    ))
                }
                </div>
            </div>
        </>
    )
}