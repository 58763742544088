import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../context/auth";
import Login from "../../pages/auth/Login";
import Loading from "./Loading";
import axios from "axios";

export default function PrivateRoute() {
  const [auth, setAuth] = useAuth();
  const [isAuthorized, setIsAuthorized] = useState(false);

  /**
   * Server side authentication:
   */
  useEffect(() => {
    const authCheck = async () => {
      try {
        /**
         * Our axios has been pre-configured with baseURL and headers so it is set to use whenever called
         */
        // const { data } = await axios.get(
        //   `${process.env.REACT_APP_API}/auth-check`,
        //   {
        //     headers: {
        //       Authorization: auth?.token,
        //     },
        //   }
        // );
        const { data } = await axios.get(`/auth-check`);
        /**
         * If the return from the server is valid:
         */
        if (data.loggedIn) {
          setIsAuthorized(true);
        } else {
          setIsAuthorized(false);
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching authentication status:", error);
      }
    };

    authCheck();
  }, [auth]);

  // useEffect(() => {
  //   if (auth?.token) {
  //     setIsAuthorized(true);
  //   } else {
  //     setIsAuthorized(false);
  //   }
  // }, [auth]);

  // return isAuthorized == true ? <Outlet /> : <Loading />;
  return isAuthorized == true ? <Outlet /> : <><p className="d-flex justify-content-center text-white mt-5">You must be logged in.</p><Loading countDown={4} redirectPage={"/login"} /></>;
}
