import { useEffect, useState } from "react";
import Jumbotron from "../components/cards/Jumbotron";
import axios from "axios";
import moment from "moment";
// import {cropTop} from "../images/"
import cropTop from "../images/crop-top.png";
import shirt from "../images/shirt.png";
import "./HomeBravo.css";
import { useNavigate } from "react-router-dom";
import { checkCloseMobileMenu } from "../util/Util";
import { useLocation } from "react-router-dom";
import splash from '../images/collage-s-logo.png'

/**
 * Custom hook to hold global context of auth status
 */
import { useAuth } from "../context/auth";

import ProductCard from "../components/cards/ProductCard";
import Categories from "../components/sections/Categories";
import Footer from "../components/sections/Footer";

export default function HomeBravo() {
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [paginate, setPaginate] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [slideIndex, setSlideIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    
    applyLazyLoad();
    loadProducts();
    getTotal();
    animateImages();
    checkCloseMobileMenu();

    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // Change image every 4 seconds

    return () => clearInterval(intervalId);
  }, []);

  const applyLazyLoad = () => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            // observer.unobserve(entry.target); // Stop observing once visible
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.5 }
    ); // Change the threshold as needed

    // Get all elements with class 'fade-load'
    const lazyLoadElements = document.querySelectorAll(".fade-load");

    // Observe each lazy load element
    lazyLoadElements.forEach((element) => {
      observer.observe(element);
    });

    // Cleanup function
    return () => {
      lazyLoadElements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  };

  const animateImages = () => {
    let slides = document.getElementsByClassName("fade-img");
    let slideIndex = Math.floor(Math.random() * slides.length);

    // Hide all images
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.opacity = "0";
    }

    // Display the current image
    if (slides[slideIndex]) slides[slideIndex].style.opacity = "1";

    setTimeout(animateImages, 4000);
  };

  useEffect(() => {
    if (paginate == 1) return;
    loadMore();
  }, [paginate]);

  const loadProducts = async () => {
    try {
      const { data } = await axios.get(`/list-products/${paginate}`);
      if (data) {
        setProducts(data);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTotal = async () => {
    try {
      const { data } = await axios.get(`/products-count`);
      setTotal(data);
    } catch (err) {
      console.log(err);
    }
  };

  function scrollToElement(elementId) {
    console.log(elementId)
    const element = document.getElementById(elementId);
    console.log(element)
    
    if (!element) return; // Element doesn't exist, stop execution.
  
    const scrollInterval = setInterval(() => {
      const elementPosition = element.getBoundingClientRect();
  
      // Check if the element is in view (within the viewport)
      if (elementPosition.top <= window.innerHeight && elementPosition.bottom >= 0) {
        clearInterval(scrollInterval); // Stop scrolling once the element is visible.
      } else {
        window.scrollBy({
          top: 300, // Scroll down by 20px on each step (adjust as needed).
          behavior: "auto"
        });
      }
    }, 100); // Repeat every 20ms to create a smooth scroll effect.
  }

  const loadMore = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/list-products/${paginate}`);
      if (data) {
        /**
         * We use spread operator to ensure we keep what was already populated and just add more to it
         */
        setProducts([...products, ...data]);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const images = [
    {
      src: "https://placido-dev-online-store.myshopify.com/cdn/shop/files/shirt-1_large.png?v=1702867760",
      alt: "ALT 1",
    },
    {
      src: "https://placido-dev-online-store.myshopify.com/cdn/shop/files/hoodie_ade58185-4263-42ad-a655-aba2392341a6_large.png?v=1707165386",
      alt: "ALT 2",
    },
    {
      src: cropTop,
      alt: "ALT 3",
    },
    {
      src: shirt,
      alt: "ALT 4",
    },
  ];

  const arr = [...products];
  const sortedBySold = arr.sort((a, b) => (a.sold < b.sold ? 1 : -1));
  const sortedByDate = arr.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  return (
    <div className="pb-5 page-component" style={{ backgroundColor: "#191919" }}>
      <div className="splash-image site-contain">
        {/** Display for medium and up  */}
        <div className="row d-none d-md-flex">
          <div className="col">
            <div style={{ marginLeft: "200px" }}>
              <h2
                style={{
                  fontSize: "xxx-large",
                  color: "#CCC",
                  paddingTop: "75px",
                  fontStyle: "italic",
                  // textAlign: "center"
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                Explore our collection
              </h2>
              <h3></h3>

              <div
                className="d-flex flex-column"
                style={{ width: "100%", marginTop: "80px" }}
              >
                <div className="d-flex justify-content-center">
                  <button
                    onClick={() => navigate("/shop")}
                    className="btn btn-alpha"
                  >
                    Shop Now
                  </button>
                  <button
                    className="btn btn-bravo mx-3"
                    onClick={() => navigate("/about")}
                  >
                    Learn More
                  </button>
                </div>

                <div
                  id="scroll-down-chevron-md"
                  style={{
                    marginTop: "15px",
                    justifyContent: "center",
                    display: "flex",
                    color: "#ccc",
                  }}
                >
                  <a style={{ color: "#ccc" }} onClick={() => scrollToElement("home-collections-bottom")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        width: "7.5rem",
                        height: "5.5rem",
                        marginTop: "20px",
                        // marginRight: "100px",
                      }}
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ marginLeft: "100px" }}
            className="col images-container hide-sm d-flex align-items-center"
          >
            {images.map((image, index) => (
              <img
                key={index}
                className="img-fluid fade-img"
                loading="lazy"
                style={{ opacity: index === currentImageIndex ? 1 : 0 }}
                src={image.src}
                alt={image.alt}
              />
            ))}
          </div>
        </div>

        {/** Display for small only   */}
        <div className="row d-flex justify-content-center d-md-none pt-5">
          <div className="col-12 images-container d-flex justify-content-center">
            {images.map((image, index) => (
              <img
                key={index}
                className="img-fluid fade-img-sm"
                loading="lazy"
                style={{ opacity: index === currentImageIndex ? 0.5 : 0 }}
                src={image.src}
                alt={image.alt}
              />
            ))}
          </div>
          <h2
            style={{
              fontSize: "xxx-large",
              color: "#CCC",
              paddingTop: "75px",
              fontStyle: "italic",
              // textAlign: "center"
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            Explore our collection
          </h2>

          <div
            className="d-flex flex-column justify-content-end align-items-center"
            style={{ width: "100%", marginTop: "80px" }}
          >
            <button
              className="btn btn-alpha mb-2 mx-5"
              onClick={() => navigate("/shop")}
            >
              Shop Now
            </button>
            <button
              className="btn btn-bravo mx-5"
              onClick={() => navigate("/about")}
            >
              Learn More
            </button>

            <div
              id="scroll-down-chevron-md"
              style={{
                marginTop: "15px",
                justifyContent: "center",
                display: "flex",
                color: "#ccc",
              }}
            >
              <a style={{ color: "#ccc" }} onClick={() => scrollToElement("home-collections-bottom")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    width: "7.5rem",
                    height: "5.5rem",
                    marginTop: "20px",
                  }}
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: "0 50px" }}>
        <h4
          id="home-collections"
          style={{ marginTop: "100px", color: "#ccc" }}
          className="d-flex justify-content-center mb-5"
        >
          Shop by categories
        </h4>
        <div className="d-flex justify-content-center">
          <Categories />
        </div>
      </div>
    </div>
  );
}
