import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../context/auth";
import Login from "../../pages/auth/Login";
import Loading from "./Loading";
import axios from "axios";
import Unauthorized from "./Unauthorized";

export default function AdminRoute() {
  const [auth, setAuth] = useAuth();
  const [isAuthorized, setIsAuthorized] = useState(false);

  /**
   * Server side authentication:
   */
  useEffect(() => {
    const authCheck = async () => {
      try {
        /**
         * Server check to see if user is admin
         */
        const { data } = await axios.get(`/admin-check`);
        /**
         * If the return from the server is valid:
         */
        if (data.isAdmin) {
          setIsAuthorized(true);
        } else {
          setIsAuthorized(false);
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching authentication status:", error);
      }
    };

    authCheck();
  }, [auth]);

  // useEffect(() => {
  //   if (auth?.token) {
  //     setIsAuthorized(true);
  //   } else {
  //     setIsAuthorized(false);
  //   }
  // }, [auth]);

  return isAuthorized == true ? <Outlet /> : <Unauthorized />;
}
