import { Link } from "react-router-dom";
import "./sections.css";
import "./Categories.css"

export default function Categories() {
  const categories = [
    {
      title: "Hoodies",
      imgSrc:
        "https://placido-dev-online-store.myshopify.com/cdn/shop/files/hoodie-0_large.png?v=1702867760",
      href: "/shop/hoodies",
    },
    {
      title: "Shirts",
      imgSrc:
        "https://placido-dev-online-store.myshopify.com/cdn/shop/files/shirt-1_large.png?v=1702867760",
      href: "/shop/shirts",
    },
    {
      title: "Sweatpants",
      imgSrc:
        "	https://placido-dev-online-store.myshopify.com/cdn/shop/files/sweatpants-1_large.png?v=1702867760",
      href: "/shop/pants",
    },
    {
      title: "Accessories",
      imgSrc:
        "https://placido-dev-online-store.myshopify.com/cdn/shop/files/hat-0_large.png?v=1702867760",
      href: "/shop/accessories",
    },
  ];
  return (
    <div className="fade-load">
      
      <div className="d-flex display-row justify-content-center" style={{ overflowX: "scroll" }}>
        {/* <div className="col-lg-4"> */}
        {categories.map((card, index) => (
          <div key={index} style={{maxWidth: '400px'}} className={`col-lg-4 col-md-6 col-10 mx-1 key-img-${index}`}>
            <Link  className={`card-link`} to={card.href}>
              <div className="cllctn-bx">
                <h4 className="cllctn-title">{card.title}</h4>
                <img className="img-fluid" src={card.imgSrc} alt={card.title} />
              </div>
            </Link>
          </div>
        ))}
        {/* <div className="cllctn-bx">
            <h4 className="cllctn-title">Hoodies</h4>
            <img
              className="img-fluid"
              src="https://placido-dev-online-store.myshopify.com/cdn/shop/files/hoodie-0_large.png?v=1702867760"
              alt="Hoodie Collection"
            />
          </div> */}
        {/* </div> */}
      </div>
      <div id="home-collections-bottom" />
    </div>
  );
}
