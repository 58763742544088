import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Badge } from "antd";
import {
  FaDollarSign,
  FaProjectDiagram,
  FaRegClock,
  FaCheck,
  FaTimes,
  FaTruckMoving,
  FaWarehouse,
  FaRocket,
} from "react-icons/fa";
import ProductCard from "../components/cards/ProductCard";
import { useCart } from "../context/cart";
import toast from "react-hot-toast";

export default function ProductView() {
  const [product, setProduct] = useState({});
  const [related, setRelated] = useState([]);
  const [cart, setCart] = useCart();

  const params = useParams();

  useEffect(() => {
    if (params.slug) loadProduct();
    window.scrollTo(0, 0);
  }, [params.slug]);

  useEffect(() => {
    if (params.slug) loadProduct();

    window.scrollTo(0, 0);
  }, []);

  const loadProduct = async () => {
    try {
      const { data } = await axios.get(`/products/${params.slug}`);
      //   console.log(data);
      setProduct(data);
      loadRelatedProducts(data._id, data.category._id);
    } catch (err) {
      console.log(err);
    }
  };

  const loadRelatedProducts = async (productId, categoryId) => {
    try {
      const { data } = await axios.get(
        `/related-products/${productId}/${categoryId}`
      );
      console.log("RELATED:", data);
      setRelated(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="row" style={{ marginTop: "5%" }}>
      <div className="col-md-9 mx-auto">
        <div
          key={product._id}
          className="card mb-3 hoverable mx-auto mt-4 product-view-card"
          style={{
            // maxWidth: "500px",
            border: "1px solid #ccc",
          }}
        >
          {/* <Badge.Ribbon text={`${product.sold} sold`} color="red"> */}
          <Badge.Ribbon
            text={`${product.quantity > 0 ? "In stock" : "Out of stock"}`}
            color="green"
            placement="start"
          >
            <div className="d-flex justify-content-center">
              <img
                className="card-img-top img-fluid"
                style={{
                  maxHeight: "500px",
                  objectFit: "cover",
                  maxWidth: "400px",
                }}
                src={`${process.env.REACT_APP_API}/products/photos/${product._id}`}
                alt={product.name}
              />
            </div>
          </Badge.Ribbon>
          {/* </Badge.Ribbon> */}

          <div className="card-body">
            <h1>{product.name}</h1>

            <h4 className="fw-bold">
              {product?.price?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </h4>

            <p className="card-text">{product.description}</p>
          </div>

          <div className="d-flex justify-content-between">
            {/* <div>
                <p className="fw-bold">
                    <FaDollarSign /> Price: {product.price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
                </p>
            </div> */}
          </div>

          <button
            className="btn btn-warning col card-button grey-outline"
            style={{
              borderBottomRightRadius: "5px",
              width: "100%",
              color: "#000",
            }}
            onClick={() => {
              setCart([...cart, product]);
              localStorage.setItem("cart", JSON.stringify([...cart, product]));
              toast.success("Product added to cart");
            }}
          >
            Add to Cart
          </button>

          {/* <p>{moment(product.createdAt).fromNow()}</p>
      <p>{product.sold} Sold</p> */}
        </div>
      </div>
      <div className="col-md-3 mt-5" style={{ maxWidth: "90%" }}>
        <h2>Related Products</h2>
        <hr />
        {/* SMALL SCREENS */}
        <div className="d-flex d-md-none overflow-auto">
          {related?.length < 1 && <p>Nothing Found</p>}
          {related?.map((p) => (
            <ProductCard
              additionalClass={"related mx-3 mw-300"}
              product={p}
              key={product._id}
            />
          ))}
        </div>
        {/* LARGE SCREENS */}
        <div className="d-none d-md-flex flex-column">
          {related?.length < 1 && <p>Nothing Found</p>}
          {related?.map((p) => (
            <ProductCard
              additionalClass={"related mx-3 h-auto"}
              product={p}
              key={product._id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
