/**
 * This function was needed because for mobile navigation, new pages would open with the mobile dropdown active when it should not be
 */
export const checkCloseMobileMenu = () => {
  let mobileMenu = document.getElementsByClassName("mobile-menu-open")[0];
  let body = document.getElementById("body");
  if (mobileMenu) {
    if (mobileMenu.classList.contains("mobile-menu-open")) {
      body.classList.remove("overflow-y-hidden")
      mobileMenu.classList.remove("mobile-menu-open");
      toggleHamburgerIcon();
    }
  }
};

const toggleHamburgerIcon = () => {
    let icon = document.getElementById("menu-hamburger-icon");
    if (
      icon.getAttribute("d") ===
      "M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
    ) {
      // Change the path to display an X
      icon.setAttribute("d", "M6 6l12 12m0-12L6 18");
    } else {
      // Change the path back to the original value
      icon.setAttribute(
        "d",
        "M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
      );
    }
  };
