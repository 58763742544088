import { useAuth } from "../../context/auth";
import UserSideMenu from "../../components/nav/UserSideMenu";
import { useEffect } from "react";
import { checkCloseMobileMenu } from "../../util/Util";

export default function Dashboard() {
  /**
   * Access to global context/username
   */
  const [auth, setAuth] = useAuth();
  useEffect(() => {
    checkCloseMobileMenu();
  },[])

  return (
    <>
      {/* <Jumbotron title={`Hello ${auth?.user?.name}`} subtitle={"Dashboard"} /> */}

      <div className="container-fluid">
        <div className="row pt-5 site-contain">
          <div className="col-md-3 pt-3">
            <UserSideMenu />
          </div>
          <div className="col-md-9">
            <div className="p-3 mt-2 b-2 h4 ml-spacer" style={{ color: "#ccc" }}>
              User Information
            </div>
            <ul className="list-group dashboard-list-group ml-spacer">
              <label>
                Name:
                <li className="list-group-item mt-1">{auth?.user?.name}</li>
              </label>
              <label>
                Email:
                <li className="list-group-item mt-1">{auth?.user?.email}</li>
              </label>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
