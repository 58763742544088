export default function Overlays() {
  const closeMenu = (testName) => {
    let mobileMenu = document.getElementById("dashboard-mobile-menu");
    let mobileMenuBody = document.getElementById("dashboard-mobile-menu-body");
    let overlay = document.getElementById("overlay-alpha");

    let mobileMenuAdminBtn = document.getElementById(
      "dashboard-mobile-admin-menu-btn"
    );
    let mobileMenuAdminBody = document.getElementById(
      "dashboard-mobile-admin-menu-body"
    );

    if (mobileMenu && mobileMenuBody) {
      if (!mobileMenu.classList.contains("mobile-menu-btn-open")) {
        mobileMenu.classList.add("mobile-menu-btn-open");
        mobileMenuBody.classList.add("mobile-menu-body-open");
        overlay.classList.add("overlay-active");
      } else {
        mobileMenu.classList.remove("mobile-menu-btn-open");
        mobileMenuBody.classList.remove("mobile-menu-body-open");
        overlay.classList.remove("overlay-active");
      }
    }

    if (mobileMenuAdminBtn && mobileMenuAdminBody) {
      {
        /** ADMIN MENU: (it shouldn't have to have different numbers for the css style applied but for some reason it does. smh. This accounts for the separate and needed css classes) */
      }
      if (
        !mobileMenuAdminBtn.classList.contains("mobile-admin-menu-btn-open")
      ) {
        mobileMenuAdminBtn.classList.add("mobile-admin-menu-btn-open");
        mobileMenuAdminBody.classList.add("mobile-admin-menu-body-open");
        overlay.classList.add("overlay-active");
      } else {
        mobileMenuAdminBtn.classList.remove("mobile-admin-menu-btn-open");
        mobileMenuAdminBody.classList.remove("mobile-admin-menu-body-open");
        overlay.classList.remove("overlay-active");
      }
    }
  };

  const closeFilter = () => {

  }
  return (
    <>
      <div onClick={() => closeMenu("alpha")} id="overlay-alpha"></div>
      <div onClick={() => closeFilter()} id="overlay-filter-options"></div>
    </>
  );
}
