import React from "react";

/**
 * @author
 * @function ConfirmBox
 **/

import "./confirmBox.css";

export const ConfirmBox = ({optionConfirm, optionCancel}) => {
  return (
    <div className="centerBox">
      <p>Updating the status of this order will alert the customer via email.</p>
      <div className="d-flex btn-ctnr">
        <button onClick={optionConfirm} className="cfm-bx-btn mx-1">Confirm</button>
        <button onClick={optionCancel} className="cfm-bx-btn mx-1">Cancel</button>
      </div>
    </div>
  );
};
