import { useAuth } from "../../context/auth";
import Jumbotron from "../../components/cards/Jumbotron";
import UserSideMenu from "../../components/nav/UserSideMenu";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import ProductCardHorizontal from "../../components/cards/ProductCardHorizontal";

export default function UserOrders() {
  /**
   * Access to global context/username
   */
  const [auth, setAuth] = useAuth();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (auth?.token) getOrders();
  }, [auth?.token]);

  const getOrders = async () => {
    try {
      /** Get orders from the endpoint */
      const { data } = await axios.get("/orders");
      setOrders(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>


      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <UserSideMenu />
          </div>
          <div className="col-md-9">
            <div className="p-3 mt-2 b-2 h4" style={{color: "#ccc"}}>User's Orders</div>
            
            {
              orders.length == 0 && <p className="h5 p-3" style={{color: "#ccc"}}>There are currently no orders</p>
            }
            {orders?.map((o, i) => {
              return (
                <div key={i} className="border shadow rounded-4 mb-5">
                  <table className="table">
                    <thead>
                      <th scope="col">#</th>
                      <th scope="col">Status</th>
                      <th scope="col">Buyer</th>
                      <th scope="col">Ordered</th>
                      <th scope="col">Payment</th>
                      <th scope="col">Quantity</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{i + 1}</td>
                        <td>{o?.status}</td>
                        <td>{o?.buyer?.name}</td>
                        <td>{moment(o?.createdAt).fromNow()}</td>
                        <td>{o?.payment?.success ? "Success" : "Failed"}</td>
                        <td>{o?.products?.length} product(s)</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="container">
                    <div className="row m-2">
                      {o?.products?.map((p, i) => (
                        <ProductCardHorizontal key={i} p={p} isRemovable={false} />
                      ))}
                    </div>
                  </div>
                </div>
                
              );
            })}
            <div className="my-5" style={{height: "200px"}} />
          </div>
        </div>
      </div>
    </>
  );
}
