import { NavLink } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { useNavigate } from "react-router-dom";
import Search from "../forms/Search";
import { useCart } from "../../context/cart";
import { Badge } from "antd";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMagnifyingGlass } from '@fortawesome/free-regular-svg-icons';
import squareLogo from "../../images/square-logo-transparent.webp";
import { CiShoppingCart } from "react-icons/ci";
import UserIcon from "./UserIcon";
import { SearchBar } from "./SearchBar";
import { HashLink } from "react-router-hash-link";

export default function Menu() {
  let menuLG = document.getElementById("top-menu-lg");
  let menuSM = document.getElementById("top-menu-sm");
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart();
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false); // Menu visibility state
  const [lastScrollY, setLastScrollY] = useState(0); // Last scroll position

  const [initials, setInitials] = useState("");
  const navigate = useNavigate();

  const logout = () => {
    setAuth({ ...auth, user: null, token: "" });
    localStorage.removeItem("auth");
    navigate("/login");
  };

  const getInitials = () => {
    // let initials = "PH";
    let initials = "N/A";
    console.log(auth);

    try {
      const firstname = auth.user.firstname;
      const lastname = auth.user.lastname;
      const firstInitial = firstname.charAt(0).toUpperCase();
      const lastInitial = lastname.charAt(0).toUpperCase();

      initials = firstInitial + lastInitial;
    } catch (err) {
      console.log("ERROR FETCHING INITIALS");
      console.log(err);
    }

    return initials;
  };

  const toggleHamburgerIcon = () => {
    let icon = document.getElementById("menu-hamburger-icon");
    if (
      icon.getAttribute("d") ===
      "M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
    ) {
      // Change the path to display an X
      icon.setAttribute("d", "M6 6l12 12m0-12L6 18");
    } else {
      // Change the path back to the original value
      icon.setAttribute(
        "d",
        "M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
      );
    }
  };

  const toggleMobileMenu = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    toggleHamburgerIcon();

    let mobileMenuContainer = document.getElementsByClassName(
      "mobile-menu-container"
    )[0];
    let body = document.getElementById("body");
    if (!mobileMenuContainer.classList.contains("mobile-menu-open")) {
      mobileMenuContainer.classList.add("mobile-menu-open");
      body.classList.add("overflow-y-hidden");
    } else {
      mobileMenuContainer.classList.remove("mobile-menu-open");
      body.classList.remove("overflow-y-hidden");
    }
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    // Show menu when scrolling up
    if (currentScrollY < lastScrollY) {
      // setShowMenu(true);
      // alert('up')
      // console.log(menu)
      if(menuLG){
        if (!menuLG.classList.contains("sticky-top"))
          menuLG.classList.add("sticky-top");
      }
      
      if(menuSM){
        if (!menuSM.classList.contains("sticky-top"))
          menuSM.classList.add("sticky-top");
          menuSM.classList.add("scrolling");
      }
      
    } else {
      // Hide menu when scrolling down
      // setShowMenu(false);
      if(menuLG){
        if (menuLG.classList.contains("sticky-top"))
          menuLG.classList.remove("sticky-top");
      }
      if(menuSM){
        if (menuSM.classList.contains("sticky-top"))
          menuSM.classList.remove("sticky-top");
          menuSM.classList.remove("scrolling");
      }
      
    }

    // Update the last scroll position
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    setInitials(getInitials());
    // console.log("USING EFFECT??!");
  }, [auth]);

  useEffect(() => {
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]); // Re-run effect if lastScrollY changes

  return (
    <>
      <div></div>
      {/* LARGE SCREENS ONLY */}
      <div id="top-menu-lg">
        {/* <div style={{background: "gray"}}>SEARCH BAR</div> */}
        <SearchBar />

        <ul className="main-menu nav d-none d-md-flex justify-content-evenly shadow-sm mb-1">
          <div className="d-flex align-items-center">
            <a href="/">
              <img
                className="img-fluid logo-lg-screen"
                // style={{ width: "70px" }}
                alt="square-logo"
                src={squareLogo}
              />
            </a>
          </div>
          <li className="nav-item">
            <NavLink
              id="menu-home-lg"
              className="nav-link nav-link-lg"
              aria-current="page"
              to="/"
              // style={{ textDecoration: "none", borderBottom: "none" }}
            >
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              id="menu-shop-lg"
              className="nav-link nav-link-lg"
              aria-current="page"
              to="/shop"
            >
              Shop
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              id="menu-about-lg"
              className="nav-link nav-link-lg"
              aria-current="page"
              to="/#about-us"
            >
              About
            </NavLink>
            {/* <a
              className="nav-link nav-link-lg"
              aria-current="page"
              href="/#about-us"
            >
              About
              </a> */}
          </li>
          <li className="nav-item">
            <NavLink
              id="menu-contact-lg"
              className="nav-link nav-link-lg"
              aria-current="page"
              to="/contact"
            >
              Contact
            </NavLink>
          </li>

          <div>
            {showSearch ? (
              <Search />
            ) : (
              <li className="nav-item nav-link d-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="11" cy="11" r="8" />
                  <line x1="21" y1="21" x2="16.65" y2="16.65" />
                </svg>
              </li>
            )}

            {/**SHOPPING CART ICON */}
            <div className="d-flex justify-content-between">
              <li className="nav-item" style={{ marginRight: "20px" }}>
                <Badge
                  style={{ marginTop: "14px" }}
                  count={cart.length > 0 ? cart.length : "0"}
                  showZero
                >
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="/cart"
                    style={{ borderBottom: "none" }}
                  >
                    <CiShoppingCart color="white" fontSize="2.5em" />
                  </NavLink>
                </Badge>
              </li>

              {/** USER ACCOUNT ICON */}
              {auth.user == null && (
                <>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/login">
                      LOGIN
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/register">
                      JOIN
                    </NavLink>
                  </li>
                </>
              )}
              {auth.user !== null && (
                <div className="dropdown">
                  <li>
                    <a
                      className="nav-link dropdown-toggle d-flex"
                      data-bs-toggle="dropdown"
                    >
                      <UserIcon initials={initials} />
                    </a>
                    <ul
                      className="dropdown-menu"
                      style={{
                        background: "#191919",
                        border: "1px solid #ccc",
                      }}
                    >
                      <li>
                        <NavLink
                          className="nav-link nav-link-dropdwn"
                          to={`/dashboard/${
                            auth?.user?.role === 1 ? "Admin" : "user/profile"
                          }`}
                        >
                          DASHBOARD
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <a
                          onClick={logout}
                          className="nav-link nav-link-dropdwn"
                          style={{ width: "100%" }}
                        >
                          LOGOUT
                        </a>
                      </li>
                    </ul>
                  </li>
                </div>
              )}
            </div>
          </div>
        </ul>
      </div>
      {/** SMALL SCREENS ONLY */}
      <ul id="top-menu-sm" className="main-menu nav d-flex d-md-none justify-content-center shadow-sm mb-2">
        {/**SHOPPING CART ICON */}
        <div
          
          style={{
            position: "absolute",
            left: "10px",
          }}
          className="d-flex justify-content-between mt-3"
        >
          <li className="nav-item" style={{ marginRight: "20px" }}>
            <Badge
              style={{ marginTop: "14px" }}
              count={cart.length > 0 ? cart.length : "0"}
              showZero
            >
              <NavLink
                className="nav-link"
                aria-current="page"
                to="/cart"
                style={{ borderBottom: "none" }}
              >
                <CiShoppingCart fontSize="2.5em" />
              </NavLink>
            </Badge>
          </li>
        </div>

        <div className="d-flex align-items-center logo-cntnr-sm">
          <a href="/">
            <img
              className="img-fluid sm-menu-logo"
              // style={{ width: "70px" }}
              alt="square-logo"
              src={squareLogo}
            />
          </a>
        </div>
        <div id="mobile-nav-menu" className="">
          <div
            className="mt-2 mx-2 sm-hamburger"
            style={{
              padding: "10px",
              border: "1px solid",
              borderRadius: "5px",
              position: "absolute",
              right: "10px",
              marginTop: "15px",
              display: "flex",
              flexDirection: "column",
            }}
            onClick={() => toggleMobileMenu()}
          >
            <span
              className="menu-txt"
              style={{
                fontSize: "12px",
                textAlign: "center",
                color: "#ccc !important",
              }}
            >
              MENU
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              style={{ width: "40px", height: "40px" }}
            >
              <path
                id="menu-hamburger-icon"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
              />
            </svg>
          </div>
        </div>
      </ul>

      <nav className="mobile-menu-container">
        <ul>
          <li>
            <NavLink id="menu-home-sm" to="/">
              {" "}
              Home{" "}
            </NavLink>
          </li>
          <li>
            <NavLink id="menu-shop-sm" to="/shop">
              Shop
            </NavLink>
          </li>
          <li>
            <NavLink id="menu-about-sm" to="/#about-us">
              About
            </NavLink>
          </li>
          <li>
            <NavLink id="menu-contact-sm" to="/contact">
              Contact
            </NavLink>
          </li>
          <li
            className="mt-4"
            style={{
              border: "1px solid",
              width: "75%",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: "5px",
            }}
          >
            <NavLink to="/cart">
              Shopping Cart: ({`${cart.length} items`})
            </NavLink>
          </li>

          {auth.user == null && (
            <div
              style={{ fontSize: "larger", fontWeight: "normal" }}
              className="d-flex justify-content-around mt-5"
            >
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/login"
                  style={{ fontWeight: "400" }}
                >
                  Login
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/register"
                  style={{ fontWeight: "400" }}
                >
                  Join
                </NavLink>
              </li>
            </div>
          )}
          {auth.user !== null && (
            <div
              style={{ fontSize: "larger", fontWeight: "normal" }}
              className="d-flex justify-content-around mt-5"
            >
              <li className="nav-item">
                <a
                  style={{ fontWeight: "400" }}
                  onClick={logout}
                  className="nav-link mobile-menu-item"
                  to="/logout"
                >
                  Logout
                </a>
              </li>
              <li className="nav-item">
                <NavLink
                  style={{ fontWeight: "400" }}
                  className="nav-link mobile-menu-item"
                  to={`/dashboard/${
                    auth?.user?.role === 1 ? "Admin" : "user/profile"
                  }`}
                >
                  Dashboard
                </NavLink>
              </li>
            </div>
          )}
        </ul>
      </nav>
    </>
  );
}
