/**
 * This hook is imported whenever we need another component to use the global context
 */
import { useState, createContext, useContext, useEffect } from "react";
import axios from "axios";

const CartContext = createContext();

/**
 *
 * Children refers the the <App/> and all the components it contains.
 * AuthContext will wrap the entire app so that its state will be available throughout
 *
 * This app will wrap the <App/> via index.js file
 */
const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([])


  return (
    <CartContext.Provider value={[cart, setCart]}>
      {children}
    </CartContext.Provider>
  );
};

/**
 * We create our own hook to be used/called elsewhere wherever needed
 * An example usage would follow: const [auth, setAuth] = useAuth();
 * This hook returns the useContext() which is the global state?
 */
const useCart = () => useContext(CartContext);

export { CartProvider, useCart };
