import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";

/**
 * @author
 * @function Footer
 **/


export default function Footer() {
  const [showSubscribeForm, setShowSubscribeForm] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [preferredName, setPreferredName] = useState('');

  const handleEmailSubscribe = async (e) => {
    e.preventDefault();
  
    //Validate see if user already exist, then add user to our emailList table.
    try {
      const { data } = await axios.post("/email-subscription", {
        emailAddress,
        preferredName
      });
  
      toast.success(`Please check your email for verification`)
      console.log("Email Subscribe", data);
    } catch (err) {
      toast.error(err.response.data.error)
      // toast.error(<div>{err.response.data.error} <a href="#">TEST</a></div>)
      // console.log(err.response.data.error);
      console.log(err);
    }
  };

  return (
    <>
      <footer
        id="footer"
        style={{
          paddingTop: "100px",
          paddingBottom: "50px",
          // backgroundColor: "rgb(25,25,25)",
          backgroundColor: "var(--background-color)",
        }}
      >
        <div
          id="footer-container"
          className="row site-contain px-1"
          style={{ borderTop: "1px solid #000", padding: "25px" }}
        >
          <div className="col-lg-4 col-md-6 col-sm-12 footer-header mb-5">
            <div className="footer-section">
              <h5>JOIN OUR LIST</h5>
              <span>
                Sign up to receive 10% off your first order and early access to
                new arrivals, sales and more!
              </span>
              {showSubscribeForm && (
                <form
                  onSubmit={handleEmailSubscribe}
                  className="mt-3 d-flex flex-column"
                >
                  <input
                    className="py-2 px-1"
                    type="email"
                    placeholder="Enter your email"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value) }
                    style={{ color: "#000" }}
                  />
                  <br/>
                  <input
                    className="py-2 px-1"
                    type="text"
                    placeholder="Preferred Name"
                    value={preferredName}
                    onChange={(e) => setPreferredName(e.target.value)}
                    style={{ color: "#000" }}
                  />
                  <input
                    className="mt-3 py-2 btn btn-alpha w-100"
                    style={{ border: "1px solid" }}
                    type="submit"
                    value="Submit"
                    // onSubmit={(e) => handleEmailSubscribe}
                    // onClick={(e) => handleEmailSubscribe}
                  />
                </form>
              )}
              {!showSubscribeForm && (
                <>
                <br/>
                  <input
                    className="mt-3 py-2 btn btn-alpha w-100"
                    style={{ border: "1px solid", width: "100%" }}
                    type="submit"
                    value="Subscribe Now"
                    // onSubmit={(e) => handleEmailSubscribe}
                    onClick={(e) => setShowSubscribeForm(true)}
                  />
                  
                </>
              )}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 footer-header mb-5">
            <div className="footer-section">
              <h5>Customer Support</h5>
              <div className="footer-links d-flex flex-column">
                <a style={{ marginLeft: "0 !important" }} href="#">
                  Terms of service
                </a>
                <a href="#">Returns</a>
                <a href="#">Contact Us</a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 footer-header">
            <div className="footer-section">
              <h5>Follow Us</h5>
              <div className="footer-links social-links d-flex justify-content-even">
                <a className="mx-2" href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="14"
                    viewBox="0 0 448 512"
                  >
                    <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"></path>
                  </svg>
                </a>
                <a className="mx-2" href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="14"
                    viewBox="0 0 448 512"
                  >
                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                  </svg>
                </a>
                <a className="mx-2" href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="14"
                    viewBox="0 0 448 512"
                  >
                    <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
