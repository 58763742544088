import React, { useEffect } from "react";
import { checkCloseMobileMenu } from "../util/Util";

/**
 * @author
 * @function About
 **/

export const About = (props) => {
  useEffect(() => {
    checkCloseMobileMenu();
  }, []);
  
  return (
    <div className="page-component">
      <div style={{ height: "65vh" }} className="site-contain">
        <h2 style={{ margin: "0px", padding: "8px" }}>
          About page coming soon
        </h2>
      </div>
    </div>
  );
};
