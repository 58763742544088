import React from "react";
import { SVGClosedEmail } from "../misc/SVGClosedEmail";
import { SVGOpenedEmail } from "../misc/SVGOpenedEmail";
import { SVGUserIcon } from "../misc/SVGUserIcon";
import { useNavigate } from "react-router-dom";

/**
 * @author
 * @function ViewEmailCard
 **/

export const ViewEmailCard = ({ data }) => {

  const styles = {
    card: {
      border: "1px solid #ccc",
      width: "100%",
      color: "#ccc",
      padding: "6px;",
      borderRadius: "5px",
      marginBottom: "10px",
      display: "flex",
      cursor: "pointer",
    },
    cardDescription: {
      display: "flex",
      flexDirection: "column",
    },
  };
  const { type } = data;
  const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-center">
      {/* <div>ViewEmailCard</div>
      {console.log(data)} */}
      {type == "ContactUs" && (
        <div
          style={styles.card}
          onClick={(e) => navigate(`/dashboard/admin/view-emails/${data._id}`)}
        >
          {data.isRead && <SVGOpenedEmail />}
          {!data.isRead && <SVGClosedEmail />}
          <div style={styles.cardDescription}>
            <span>Message From:</span>
            <span>{data.name}</span>
            <span style={{ fontSize: "small" }}>{new Date(data.createdAt).toLocaleDateString()}</span>
          </div>
        </div>
      )}
      {type == "EmailList" && (
        <div style={styles.card}>
          <SVGUserIcon />
          <div style={styles.cardDescription}>
            <span>Email list subscriber:</span>
            <span>{data.preferredName}</span>
            <span style={{ fontSize: "small" }}>{data.emailAddress}</span>
            <span style={{ fontSize: "small" }}>{new Date(data.createdAt).toLocaleDateString()}</span>
          </div>
        </div>
      )}
    </div>
  );
};
