import { useAuth } from "../../context/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import DropIn from "braintree-web-drop-in-react";
import { useCart } from "../../context/cart";
import toast from "react-hot-toast";

export default function UserCartSidebar({ cartTotal }) {
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart();
  const [clientToken, setClientToken] = useState();
  const [instance, setInstance] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getClientToken = async () => {
    try {
      const { data } = await axios.get("/braintree/token");
      setClientToken(data.clientToken);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePurchase = async () => {
    try {
      setLoading(true);
      /** Get the none */
      const { nonce } = await instance.requestPaymentMethod();

      /**Send the none to the backend and get the response */
      const { data } = await axios.post(`/braintree/payment`, {
        nonce,
        cart,
      });

      /**Recieve response, set loading to true */
      setLoading(false);
      /**Remove the cart from local storage after transaction. (Should probably be within if/else on success )  */
      localStorage.removeItem("cart");

      setCart([]);

      navigate("/dashboard/user/orders");

      toast.success("Payment successful");

      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      getClientToken();
    }
  }, []);

  return (
    <div className="col-md-4">
      <div style={{color: "#ccc"}}>
        <h4>Your cart summary</h4>
        {/* Total / Address / Payments */}
        <hr />
        <h6 className="mb-5">Total: {cartTotal}</h6>
        {auth?.user?.address ? (
          <>
            <div>
              <hr />
              <h4>Delivery address:</h4>
              <h5>{auth?.user?.address}</h5>
            </div>
            <button
              onClick={() => navigate("/dashboard/user/profile")}
              className="btn btn-outline-warning"
              disabled={!auth?.user?.address || !instance}
            >
              Update address
            </button>
          </>
        ) : (
          <div className="mb-3">
            {auth?.token ? (
              <button
                className="btn btn-outline-warning"
                onClick={() => navigate("/dashboard/user/profile")}
              >
                Add delivery address
              </button>
            ) : (
              <button
                className="btn btn-outline-danger mb-3"
                onClick={() =>
                  navigate("/login", {
                    state: "/cart",
                  })
                }
              >
                Login to checkout
              </button>
            )}
          </div>
        )}
      </div>
      <div className="mt-1">
        {!clientToken || !cart?.length ? (
          ""
        ) : (
          <>
            <DropIn
              options={{
                authorization: clientToken,
              }}
              onInstance={(instanceFromBraintree) =>
                setInstance(instanceFromBraintree)
              }
            />
            <div className="d-flex justify-content-center">
              <button
                style={{ width: "75%" }}
                className="btn btn-primary"
                onClick={handlePurchase}
              >
                {loading ? "Processing..." : "Purchase"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
