import { useAuth } from "../../context/auth";
import Jumbotron from "../../components/cards/Jumbotron";
import UserSideMenu from "../../components/nav/UserSideMenu";
import { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

export default function UserProfile() {
  /**
   * Access to global context/username
   */
  const [auth, setAuth] = useAuth();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put("/profile", {
        name,
        password,
        address,
      });

      if (data?.error) {
        toast.error(data.error);
      } else {
        //   console.log("profile updated => ", data);
        setAuth({ ...auth, user: data });

        /**
         * Local storage update logic
         */
        let ls = localStorage.getItem("auth");
        ls = JSON.parse(ls);
        ls.user = data;
        localStorage.setItem("auth", JSON.stringify(ls));
        toast.success("Profile updated");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (auth?.user) {
      const { name, email, address } = auth.user;
      setName(name);
      setEmail(email);
      setAddress(address);
    }
  }, [auth?.user]);

  return (
    <div>
      <div className="container-fluid">
        <div className="row pt-5 site-contain">
          <div className="col-md-3">
            <UserSideMenu />
          </div>
          <div className="col-md-9">
            <div
              className="p-3 mt-2 b-2 h4 ml-spacer"
              style={{ color: "#ccc" }}
            >
              User Profile
            </div>
            <form
              onSubmit={handleSubmit}
              className="dashboard-list-group d-flex flex-column ml-spacer"
            >
              <label>
                Username:
                <input
                  type="text"
                  className="form-control m-2 p-2"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoFocus={true}
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  className="form-control m-2 p-2"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled
                />
              </label>
              <label>
                Password:
                <input
                  type="password"
                  className="form-control m-2 p-2"
                  placeholder="Enter your pasword"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
              <label>
                Address:
                <textarea
                  className="form-control m-2 p-2"
                  placeholder="Enter your address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </label>
              <div style={{width: "70%", display: "flex"}}>
                <button
                  style={{ width: "50%" }}
                  className="btn btn-primary m-2 p-2 mx-auto"
                >
                  Submit Edits
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
